.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.App-header {
  font-size: calc(10px + 2vmin);
}

.experiment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #fdfdfd;
  border-radius: 10px;
  padding: 1em;
  margin: 0.5em;
}


input, select {
  max-width: 300px;
}