/* when in normal mode */
.image-gallery-image {
    aspect-ratio: 16/9;
    object-fit: cover;
}

/* when fullscreen */
.fullscreen .image-gallery-image {
    height: unset;
    object-fit: contain;
}

.html-description .fa-solid, .html-description .fa-regular {
    margin-left: 0.5em;    
    margin-right: 0.5em;    
}

.html-description {
    font-size: 0.85em;
    overflow-y: scroll;
    height: 400px;
}